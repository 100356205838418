import {useEffect, useState} from 'react'
import RenderHtml from './RenderHtml'
import GetContentTypes from '../GetContentTypes'
import ImageHtml from './ImageHtml'
import NavigationHtml from './NavigationHtml'

const HeaderHtml = ({contentType, innerHtml, cssClass, current}) => {
    const [menuCount, setMenuCount] = useState(0)
    const [contentTypes, setContentTypes] = useState()
    const [contentTypeIdList, setContentTypeList] = useState()
    const [width, setWidth] = useState(window.innerWidth);
    const [navClass, setNavClass] = useState('');
    
    useEffect(() => {
        if(contentType){
            setMenuCount(contentType.values[0].relations.filter((rel) =>
                rel.contentModel.modelName === "Navigation"
            ).length)
            setContentTypeList(contentType.values[0].relations.map((rel)=> {
                return rel.id
            }))
        }
        handleWindowSizeChange()
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    },[contentType, current])

    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
        let nc = ''
        var wrapper = document.getElementById('cc_wrapper')
        wrapper.classList.remove("xs","s","md","lg","xl","xxl")
        if(window.innerWidth < 576){
            nc ='xs'
            global.config.IsMobil = true
        }
        if(window.innerWidth >= 576){
            nc = 's'
            global.config.IsMobil = true
        }
        if(window.innerWidth >= 768){
            nc = 'md'
            global.config.IsMobil = false
        }
        if(window.innerWidth >= 992){
            nc = 'lg'
        }
        if(window.innerWidth >= 1200){
            nc = 'xl'
        }
        if(window.innerWidth >= 1400){
            nc = 'xxl'
        }
        setNavClass(nc)
        wrapper.classList.add(nc)
    }


    
    let content = ''
    let headerClass = ''

    if(contentTypes){
        let curMenu = 0;
        
        content = contentTypes.map((rel)=> {
            if(rel.contentModel.modelName === "Navigation"){
                curMenu++
                headerClass = 'navigation '+((menuCount > 1 && curMenu === 1 ) ? 'top_top' : '')
                return <NavigationHtml key={`navigation-${rel.id}`} contentType={rel} 
                               innerHtml={true} 
                               cssClass={`${headerClass} ${cssClass}`}
                               current={current}/>
            }
            if(rel.contentModel.modelName === "Image"){
                headerClass = 'top_logo'
                return <ImageHtml key={`image-${rel.id}`} contentType={rel} 
                               innerHtml={true} 
                               cssClass={`${headerClass} ${cssClass}`}
                               current={current}/>
            }
            
        })

    }

                

    return (
        <>
        {(contentTypeIdList) && <GetContentTypes contentTypeIds={contentTypeIdList} setContentTypes={setContentTypes} />}
        <header className={`cc_header`}>
            {content}
        </header>
        </>
    )
}

export default HeaderHtml
