import {useEffect} from 'react'
import { useParams } from 'react-router-dom'

const GetCurrentContentType = ({frontId, setCurrent}) => {
    let { area, url } = useParams();


    useEffect(() => {
        const getData = async () => {
            const data = await fetchData()
            if(data){
                setCurrent(data.contentType)
            }
        }
        setCurrent()
        getData()
    },[area, url])
    const fetchData = async () => {

        var graphql = JSON.stringify({
            query: `query ($id: Guid, $url: String, $area: String){
                contentType(id: $id, url: $url, area: $area) {
                    id
                    contentTypeId 
                    contentTypeName
                    published
                    publishDate
                    values(url:$url){
                        id
                        settingId
                        contentPublished
                        settingAlias
                        relations(url:$url){
                            id
                            contentTypeName
                            contentTypeUrl
                            contentModel{
                                modelName
                                modelUrl
                                isArea
                            }
                        }
                    }
                    settings{
                        id
                        settingType
                        settingName
                        settingAlias
                        settingDescription
                        showDescription
                        defaultValue
                        required
                    }
                    contentModel{
                        id
                        modelName
                    }
                }
            }`,
            variables: {
                url: url
                , area: area
                , id: (frontId ? frontId : null)
            }
        })
        const res = await fetch(global.config.GraphQL.url + global.config.Editor.publicToken ,{
            method:'POST',
            headers:{
                'Authorization':'Basic ' + global.config.Editor.authToken
                ,'Content-Type': 'application/json'
            },
            body: graphql
        })
        .then(response => response.text())
        .then(result => {return JSON.parse(result).data})
        .catch(error => console.log('error', error))
        const data = await res
        return data
    }

    return (<></>)

}

export default GetCurrentContentType
