import React from 'react'
import RenderHtml from './RenderHtml'

const FooterHtml = ({contentType, innerHtml}) => {
    return (
        <footer className="footer">
            {(contentType) &&
                contentType.values[0].relations.map((rel)=>
                    <RenderHtml contentType={rel} innerHtml={innerHtml} />
                )
            }
        </footer>
    )
}

export default FooterHtml
