module.exports = global.config = {
    Api: {
        url: 'https://test.cloudcontent.dk/api/'
    },GraphQL: {
        url: 'https://test.cloudcontent.dk/graphql/'
    },
    LayoutID:'17f1cd7c-4933-403e-840f-057df57c9cbb',
    ArticleTypeName: 'Artikel',
    PageTypeName: "Side",
    IsMobil:false,
    Editor:{
        publicToken:"7fef1c02-7b22-4b75-8210-2a622874ad0c"
        ,authToken:"dGVzdEB0ZXN0c2VydmVyMS5kazp0ZXN0ISEhIQ=="//"aW5mb0BuZXd3d2ViLmRrOkRlbW9AY2MyMDIxIQ=="//
    },
    Profile:{

    },
    History:[]
};