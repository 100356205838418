import './css/App.css';
import {useState, useEffect} from 'react'
import Content from './Content'
import GetCurrentContentType from './GetCurrentContentType'
import RenderHtml from './components/RenderHtml'
import HeaderHtml from './components/HeaderHtml'
import PageHtml from './components/PageHtml'
import FooterHtml from './components/FooterHtml'
import { Helmet } from 'react-helmet'
import GetContentTypes from './GetContentTypes'

import { BrowserRouter as Router, Route, useRouteMatch, useParams } from 'react-router-dom'

function App() {
  const [init, setInit] = useState(false)
  const [current, setCurrent] = useState()
  const [headerId, setHeaderId] = useState()
  const [header, setHeader] = useState()
  const [footerId, setFooterId] = useState()
  const [footer, setFooter] = useState()
  const [frontId, setFrontId] = useState()
  const [front, setFront] = useState()
  const [searchable, setSearchable] = useState(false)
  const [metaDescription, setMetaDescription] = useState('')
  const [title, setTitle] = useState('')
  const [contentTypes, setContentTypes] = useState()
  const [contentTypeIdList, setContentTypeIdList] = useState()
  

  useEffect(()=>{
      if(contentTypes){
        setHeader(contentTypes.filter((ct)=> ct.id === headerId)[0])
        setFooter(contentTypes.filter((ct)=> ct.id === footerId)[0])
        setFront(contentTypes.filter((ct)=> ct.id === frontId)[0])
        //setCurrent(contentTypes.filter((ct)=> ct.id === frontId)[0])
      }
    
  },[contentTypes])

  useEffect(()=>{
    if(current){
      setTitle(current.contentTypeName)
      setSearchable(current.values.filter((value) => value.settingAlias === "searchable")[0] && current.values.filter((value) => value.settingAlias === "searchable")[0].contentPublished)
      setMetaDescription(current.values.filter((value) => value.settingAlias === "metaDescription")[0] && current.values.filter((value) => value.settingAlias === "metaDescription")[0].contentPublished)
    }
  },[current])

  const getData = async () => {
      const data = await fetchLayout()
      if(data.contentType){
        setHeaderId(data.contentType.values.filter((v) => v.settingAlias === "header")[0].relations[0].id)
        setFrontId(data.contentType.values.filter((v) => v.settingAlias === "content")[0].relations[0].id)
        setFooterId(data.contentType.values.filter((v) => v.settingAlias === "footer")[0].relations[0].id)
      }
  }

  const runInit = () => {
    if(!init){
      setInit(true)
      getData()
    }
  }

  const fetchLayout = async () => {

    var graphql = JSON.stringify({
        query: `query {
                  contentType( id: "${global.config.LayoutID}") {
                    values(alias:["header","footer","content"]){
                      settingAlias
                      relations{
                        id
                      }
                    }
                  }
                }`,
        variables: {
            "id": global.config.LayoutID
        }
      })
    const res = await fetch(global.config.GraphQL.url + global.config.Editor.publicToken ,{
        method:'POST',
        headers:{
            'Authorization':'Basic ' + global.config.Editor.authToken
            ,'Content-Type': 'application/json'
        },
        body: graphql
    })
    .then(response => response.text())
    .then(result => {  
      var data = JSON.parse(result).data        
      if(data.contentType){
        setContentTypeIdList(data.contentType.values.map((v) => v.relations[0].id))
      }
      return data
    })
    .catch(error => console.log('error', error))
    const data = await res
    return data
  }

  runInit()

  return (
    <>
      {(contentTypeIdList) && <GetContentTypes contentTypeIds={contentTypeIdList} setContentTypes={setContentTypes} />}
      <Helmet>
        <title>{ title }</title>
        <meta name="description" content={metaDescription} />
        <meta name="robots" content={searchable ? "index, follow" : "no-index, no-follow"}/>
      </Helmet>
      <Router>
        {
          (header) && <HeaderHtml contentType={header} cssClass="" current={current}/>
        }
        <main>
        <Route path="/" exact strict render={()=> 
          (front) && <GetCurrentContentType frontId={frontId} setCurrent={setCurrent}/>}
        />
        <Route path="/" render={()=> 
          <>
          <Content  current={current} setCurrent={setCurrent}/>
          {current && 
                <PageHtml contentType={current} cssClass="" current={current}/>}
          </>
        }/>
        </main>
      {
          (footer) && <FooterHtml contentType={footer} cssClass="" current={current}/>
      }
      </Router>
    </>
  );
}

export default App;
