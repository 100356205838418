import {useState, useEffect} from 'react'
import RenderHtml from './RenderHtml'
import { Link, useHistory } from 'react-router-dom'
import NavigationItem from './NavigationItemHtml'

const NavigationItemHtml = ({contentTypeId, innerHtml, cssClass, current, setActiveClass}) => {
    const [contentType, setContentType] = useState()
    const [menuText, setMenuText] = useState('')
    const [link, setLink] = useState('')
    const [thisActiveClass, setThisActiveClass] = useState()
    const [navItem, setNavItem] = useState()
    const [hasSubmenu, setHasSubmenu] = useState(false)
    const [submenuIsOpen, setSubmenuIsOpen] = useState(false)

    const fetchContentType = async () => {

        var graphql = JSON.stringify({
            query: `query ($url:String){
                        contentType(id:"${contentTypeId}",url:$url) {
                            id
                            contentTypeId 
                            contentTypeName
                            published
                            publishDate
                            values(url:$url){
                                id
                                settingId
                                contentPublished
                                settingAlias
                                relations(url:$url){
                                    id
                                    contentTypeName
                                    contentTypeUrl
                                    contentModel{
                                        modelName
                                        modelUrl
                                        isArea
                                    }
                                }
                            }
                            contentModel{
                                id
                                modelName
                            }
                        }
                    }`,
            variables: {
                url:'url'
            }
          })
        const res = await fetch(global.config.GraphQL.url + global.config.Editor.publicToken ,{
            method:'POST',
            headers:{
                'Authorization':'Basic ' + global.config.Editor.authToken
                ,'Content-Type': 'application/json'
            },
            body: graphql
        })
        .then(response => response.text())
        .then(result => {return JSON.parse(result).data})
        .catch(error => console.log('error', error))
        const data = await res
        return data

    }

    
    useEffect(() => {
        const getContentType = async () => {
            const data = await fetchContentType()
            setContentType(data.contentType)
        }
        getContentType()

    },[contentTypeId])
    
    useEffect(()=>{
        if(contentType){
            setNavItem(contentType)
            try{
                var menuTextVal = contentType.values.filter((value) => value.settingAlias === "menuText")[0]
                setMenuText(menuTextVal.contentPublished)
                var linkVal = contentType.values.filter((value) => value.settingAlias === "link")[0].relations[0]
                if(linkVal){
                    setLink(linkVal.contentModel.isArea ? '/' + linkVal.contentModel.modelUrl + '/' + linkVal.contentTypeUrl : '/' + linkVal.contentTypeUrl)
                    if(current){
                        setThisActiveClass((linkVal.id === current.id ) ? 'current' : '')
                        setActiveClass((linkVal.id === current.id ) ? 'current' : '')
                    }
                }else{

                }
                setHasSubmenu(contentType.values.filter((value) => value.settingAlias === "submenu")[0].relations.length > 0)
            }catch(err){}
            setSubmenuIsOpen(false)
        }
    },[contentType, current, thisActiveClass, global.config.IsMobil])

    const handleOpenSubmenu = (action) => {
        setSubmenuIsOpen((action === "open") ? true : false)
    }

    let content

    if(menuText && link){
        if(global.config.IsMobil === false){
            content = (
                <li key={`li-${contentType.id}`} className={`${thisActiveClass} ${submenuIsOpen ? 'active' : ''}`} >
                    <Link key={`link-${contentType.id}`} to={link}>{menuText}</Link>
                    {
                        (hasSubmenu) &&
                        <ul key={`ul-${contentType.id}`}>
                            {contentType.values.filter((value) => value.settingAlias === "submenu")[0].relations.map((rel)=>
                                <NavigationItem key={`navitem-${rel.id}`} contentTypeId={rel.id} innerHtml={innerHtml} cssClass={cssClass} current={current} setActiveClass={setThisActiveClass}/>
                            )
                            }
                        </ul>
                    }
                </li>
            )
        }else{
        
            content = (
                <li key={`li-${contentType.id}`} className={`${thisActiveClass} ${submenuIsOpen ? 'active' : ''}`} onClick={()=> handleOpenSubmenu(submenuIsOpen ? "close" : "open")}>
                    {((hasSubmenu && submenuIsOpen) || !hasSubmenu ) ?
                        <Link key={`link-${contentType.id}`} to={link}>
                            {menuText}
                            {(hasSubmenu && submenuIsOpen) && <i className="sprite-icon sprite-icon-x075 icon-minus"></i>}
                        </Link>
                    :
                        <a key={`a-${contentType.id}`} href="#" onclick="return false;">{menuText}<i className="sprite-icon sprite-icon-x075 icon-plus"></i></a>
                    }
                    {
                        (hasSubmenu) &&
                        <ul key={`ul-${contentType.id}`}>
                            {contentType.values.filter((value) => value.settingAlias === "submenu")[0].relations.map((rel)=>
                                <NavigationItem contentTypeId={rel.id} innerHtml={innerHtml} cssClass={cssClass} current={current} setActiveClass={setThisActiveClass}/>
                            )
                            }
                        </ul>
                    }
                </li>
            )
        }
    }

    return (
        <>
            {content}
        </>
    )
}

export default NavigationItemHtml
