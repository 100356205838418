import {useState, useEffect} from 'react'
import NavigationItemHtml from './NavigationItemHtml'

const NavigationHtml = ({contentType, innerHtml, cssClass, current}) => {
    const [activeClass, setActiveClass] = useState()
    const [navItem, setNavItem] = useState()
    const [mainmenuIsOpen, setMainmenuIsOpen] = useState()

    useEffect(() => {
        if(current && contentType){
            setNavItem(contentType)
            setActiveClass((contentType.values[0].relations.filter((rel)=> rel.id === current.id).length > 0 && innerHtml === true) && 'active')
        }
        setMainmenuIsOpen(false)
    },[current])

    const handleOpenMainmenu = (action) => {
        setMainmenuIsOpen((action === "open") ? true : false)
    }

    let content

    content = (
        <ul key={`ul-${contentType.id}`}>
        {(navItem) &&
            navItem.values[0].relations.map((rel)=>
                <NavigationItemHtml key={`navitem-${rel.id}`} contentTypeId={rel.id} innerHtml={innerHtml} cssClass={cssClass} current={current} setActiveClass={setActiveClass}/>
            )
        }
        </ul>
    )

    return (  
        <>         
        {
            ((mainmenuIsOpen) ? <nav key={`nav-${contentType.id}`} className="open"> 
                {content}
            </nav>
            : 
            <nav key={`nav-${contentType.id}`}>
                {content}
            </nav>
            )
        }      
        {
            (!cssClass.includes("top_top")) &&
            <div className="cc_menu_icon" onClick={()=> handleOpenMainmenu(mainmenuIsOpen ? "close" : "open")}>
                <i className="sprite-icon icon-menu sprite-icon-x150"></i>
            </div>
        }
        </>
    )
}

export default NavigationHtml
