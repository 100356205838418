import {useState, useEffect} from 'react'
import RenderHtml from './RenderHtml'
import GetContentTypes from '../GetContentTypes'

const PageHtml = ({contentType}) => {
    const [contentTypes, setContentTypes] = useState()
    const [contentTypeIdList, setContentTypeList] = useState()


    useEffect(()=>{
        if(contentType){
            setContentTypeList(contentType.values.filter((v)=> v.settingAlias === "content")[0].relations.map((rel)=> {
                return rel.id
            }))
        }
    },[contentType])

    if(contentType){
        if(contentType.contentModel.modelName === global.config.ArticleTypeName){
            return (<RenderHtml key={`render-${contentType.id}`} contentType={contentType}/>)
        }
    }

    let content

    if(contentTypes){
        content = (contentTypes.map((rel)=>{
            return (<RenderHtml key={`render-${rel.id}`} contentType={rel}/>)
        }))
    }

    return (
        <>
            {(contentTypeIdList) && <GetContentTypes contentTypeIds={contentTypeIdList} setContentTypes={setContentTypes} />}
            {content}
        </>
    )
}

export default PageHtml
