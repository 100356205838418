import { Route, useRouteMatch, useParams } from 'react-router-dom'
import GetCurrentContentType from './GetCurrentContentType'

const Content = ({frontId, setCurrent}) => {
    let { url } = useRouteMatch();
    return (
        <>
            <Route path={`/:url`} exact strict>
                <GetCurrentContentType setCurrent={setCurrent}/>
            </Route>
            <Route path={`/:url/`} exact strict>
                <GetCurrentContentType setCurrent={setCurrent}/>
            </Route>
            <Route path={`/:area/:url`} exact strict>
                <GetCurrentContentType setCurrent={setCurrent}/>
            </Route>
            <Route path={`/:area/:url/`} exact strict>
                <GetCurrentContentType setCurrent={setCurrent}/>
            </Route>
        </>
    )
}
export default Content
